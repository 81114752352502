export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [4,2,3];

export const dictionary = {
		"/(index)": [~11],
		"/admin": [~14,[4]],
		"/admin/resorts": [~15,[4]],
		"/admin/resorts/add": [~23,[4]],
		"/admin/resorts/[resortId]": [~16,[4]],
		"/admin/resorts/[resortId]/control-points": [~17,[4]],
		"/admin/resorts/[resortId]/courses/add": [~20,[4]],
		"/admin/resorts/[resortId]/courses/[courseId]": [~18,[4]],
		"/admin/resorts/[resortId]/courses/[courseId]/control-points/[controlPointId]/edit": [~19,[4]],
		"/admin/resorts/[resortId]/edit": [~21,[4]],
		"/admin/resorts/[resortId]/map/[mode=addOrEdit]": [~22,[4]],
		"/admin/users": [~24,[4]],
		"/admin/users/[userId]/edit": [~25,[4]],
		"/(auth)/email-verification": [~5,[2]],
		"/(auth)/login": [~6,[2]],
		"/(auth)/password-reset": [7,[2]],
		"/(auth)/password-reset/[token]": [~8,[2]],
		"/(legal)/privacy-policy": [12,[3]],
		"/(auth)/profil": [~9,[2]],
		"/resorts": [~26],
		"/resorts/[resortId]": [~27],
		"/resorts/[resortId]/courses/[courseId]": [~28],
		"/(auth)/signup": [~10,[2]],
		"/support": [~29],
		"/(legal)/terme-of-services": [13,[3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';